<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

// TODO precisa pegar o enum do backend
const TipoParticipanteEnum = {
  COLABORADOR: 0,
  VAGA_ABERTA: 1,
  TERCEIRO: 2,
  VAGA_NOVA: 3
};

export default {
  components: {
    FormModal,
    MasterDetail
  },
  data() {
    return {
      opts: {
        bps: [],
        participants: [],
        perfis: [],
        tipoParticipantes: [
          {
            id: TipoParticipanteEnum.COLABORADOR,
            value: "Colaborador interno"
          },
          {
            id: TipoParticipanteEnum.VAGA_ABERTA,
            value: "Vagas em aberto"
          },
          {
            id: TipoParticipanteEnum.TERCEIRO,
            value: "Terceiros"
          },
          {
            id: TipoParticipanteEnum.VAGA_NOVA,
            value: "Novas vagas"
          }
        ],
        statusRubrica: [
          {
            id: 1,
            value: "Ativo"
          },
          {
            id: 0,
            value: "Inativo"
          }
        ]
      },
      isVisibleModalParticipante: false,
      modalParticipanteValue: {},
      participantType: TipoParticipanteEnum.COLABORADOR,
      selectedItem: {},
      selection: [],
      tieModal: {
        error: "",
        opened: false,
        data: {}
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      const that = this;

      return [
        {
          key: "tipo",
          name: "Tipo de participante",
          type: this.$fieldTypes.RADIO,
          rules: [{ rule: "required" }],
          rel: { to: "tipoParticipantes", id: "id", name: "value" },
          colSize: 6,
          valueChanged: tipo => {
            that.participantType = tipo;
          }
        },
        {
          key: "identificador",
          name: "Identificador da vaga",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: this.participantType !== TipoParticipanteEnum.VAGA_ABERTA,
          colSize: 6
        },
        {
          key: "matricula",
          name: "Matrícula",
          type: this.$fieldTypes.TEXT,
          hideInform: this.participantType !== TipoParticipanteEnum.COLABORADOR,
          colSize: 6
        },
        {
          key: "nome",
          name: "Nome participante",
          type: this.$fieldTypes.TEXT,
          hideInform: ![
            TipoParticipanteEnum.COLABORADOR,
            TipoParticipanteEnum.TERCEIRO
          ].includes(this.participantType),
          rules: [{ rule: "required" }],
          colSize: 6
        },
        {
          key: "perfil_id",
          name: "Senioridade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "perfis", key: "id", name: "senioridade" },
          colSize: 6,
          valueChanged: perfilId => {
            const perfil = that.opts.perfis.find(({ id }) => perfilId === id);

            if (perfil) {
              that.selectedItem.especialidade = perfil.especialidade;
              return;
            }

            that.selectedItem.especialidade = null;
          }
        },
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: ![
            TipoParticipanteEnum.COLABORADOR,
            TipoParticipanteEnum.TERCEIRO
          ].includes(this.participantType),
          rules: [{ rule: "required" }],
          colSize: 6
        },
        {
          key: "especialidade",
          name: "Especialidade",
          type: this.$fieldTypes.TEXT,
          hideInform: this.participantType === TipoParticipanteEnum.TERCEIRO,
          editable: false,
          colSize: 6
        },
        {
          key: "habilidades",
          name: "Habilidades",
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "custo_medio",
          name: "Custo médio",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          hideInform: this.participantType !== TipoParticipanteEnum.TERCEIRO,
          colSize: 6
        },
        {
          key: "empresa",
          name: "Empresa",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          hideInform: this.participantType !== TipoParticipanteEnum.TERCEIRO,
          colSize: 12
        },
        {
          key: "formacao",
          name: "Formação técnica",
          type: this.$fieldTypes.TEXT,
          hideInform: ![
            TipoParticipanteEnum.COLABORADOR,
            TipoParticipanteEnum.VAGA_ABERTA
          ].includes(this.participantType),
          colSize: 6
        },
        {
          key: "rede",
          name: "LinkedIn",
          type: this.$fieldTypes.TEXT,
          hideInform: this.participantType !== TipoParticipanteEnum.COLABORADOR,
          colSize: 12
        }
      ];
    },
    contextOptions() {
      return [
        {
          name: "Vincular a um Business Plan",
          cb: async row => {
            if (!this.selection.includes(row)) {
              this.selection.push(row);
            }

            await this.getBusinessPlans();

            this.tieModal.data = {
              participants: this.selection.map(({ id }) => id)
            };
            this.tieModal.opened = true;
          }
        }
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `/v1/ativacaoped/${this.clientId}/participantes`
      );
      const opts = this.opts;
      return {
        ...resource,
        async get(...args) {
          let response = {};
          opts.participants = [];

          try {
            response = await resource.get(...args);
            const { data, error } = response;

            if (error) {
              throw error;
            }

            if (!Array.isArray(data)) {
              throw new Error("Data não encontrada");
            }

            response.data = data.map(({ matricula, perfil, ...rest }) => ({
              ...rest,
              especialidade: perfil?.especialidade,
              matricula: matricula ?? "Sem matricula",
              perfil_id: perfil?.id
            }));
            opts.participants = response.data.map(participant => ({
              ...participant,
              label: `${participant.matricula} — ${participant.nome}`
            }));
          } catch (error) {
            this.notify(error);
            response.data = [];
            response.error = null;
          }
          return response;
        }
      };
    },
    tieFields() {
      return [
        {
          key: "participants",
          name: "Participantes",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "participants", key: "id", name: "label" },
          rules: [{ rule: "required" }]
        },
        {
          key: "businessPlanId",
          name: "Business Plan",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "bps", key: "id", name: "nome" },
          rules: [{ rule: "required" }]
        }
      ];
    }
  },
  created() {
    this.getPerfis();
  },
  methods: {
    doExport() {
      window.alert("TO DO Implementar");
    },
    doImport() {
      window.alert("TO DO Implementar");
    },
    async doTieParticipants(values, closeFn) {
      try {
        const { businessPlanId, participants } = values;

        if (
          !businessPlanId ||
          !Array.isArray(participants) ||
          !participants.length
        ) {
          return;
        }

        this.tieModal.error = "";
        const { nome: bpName } = this.opts.bps.find(
          ({ id }) => businessPlanId === id
        );
        const resource = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-plans/${businessPlanId}/participantes`
        );
        await resource.save({ participantes: participants });
        const { length } = participants;
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title:
            length > 1 ? "Participante vinculado" : "Participantes vinculados",
          text: `${length} participante${
            length > 1 ? "s foram" : " foi"
          } adicionado${length > 1 ? "s" : ""} ao BP ${bpName} com sucesso.`
        });
        this.selection = [];

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.tieModal.error = this.errorHandler(error);
      }
    },
    async getBusinessPlans() {
      try {
        this.opts.bps = [];
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-plans/selecao`
        );
        this.opts.bps = await get();
      } catch (error) {
        this.opts.bps = [];
      }
    },
    async getPerfis() {
      try {
        this.opts.perfis = [];
        const { get } = this.apiResource(
          `v1/ativacaoped/${this.clientId}/perfil/selecao`
        );
        this.opts.perfis = await get();
      } catch (error) {
        this.opts.perfis = [];
      }
    },
    onOpenFormDialog(selectedItem) {
      this.selectedItem = selectedItem;
      this.selectedItem.tipo = TipoParticipanteEnum.COLABORADOR;
      this.participantType = TipoParticipanteEnum.COLABORADOR;
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :cols="cols"
    :contextOptions="contextOptions"
    :customResource="customResource"
    :formWidth="850"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :selection.sync="selection"
    selectionCol
    serverPagination
    @onOpenFormDialog="onOpenFormDialog"
  >
    <!-- Modal de vinculo -->
    <FormModal
      :cols="tieFields"
      :errorMessage="tieModal.error"
      :opened.sync="tieModal.opened"
      :opts="opts"
      title="Vincular participantes a um Business Plan"
      :value.sync="tieModal.data"
      @save="doTieParticipants"
    />

    <!-- TODO fica para próxima etapa -->
    <!-- <div class="d-flex">
      <v-spacer></v-spacer>
      <div
        aria-label="exportButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doExport()"
      >
        <v-icon>mdi-tray-arrow-down</v-icon> Exportar modelo
      </div>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div> -->
  </MasterDetail>
</template>
